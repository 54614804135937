
export const CATEGORY_LIST = 'CATEGORY_LIST'; // 大分类列表
export const LOCATION_CITY = 'LOCATION_CITY'; // 定位城市
export const SEARCH_HISTORY_LIST = 'SEARCH_HISTORY_LIST'; // 搜索历史列表
export const BROWSE_HISTORY_LIST = 'BROWSE_HISTORY_LIST'; // 浏览历史列表
export const USER_TOKEN = 'USER_TOKEN'; // user token
export const ORDER_PAYMENT_LIST = 'ORDER_PAYMENT_LIST'; // 订单支付列表
export const ADDRESS_INFO = 'ADDRESS_INFO'; // 地址信息
export const TEMP_ADDRESS = 'TEMP_ADDRESS'; // 临时地址
export const WX_OPENID = 'WX_OPENID'; // 微信用户的openid
export const WX_USERID = 'WX_USERID'; // 微信用户的openid
