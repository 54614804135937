<template>
  <!-- 底部导航 -->
  <footer id="tabNav">
    <section v-for="(item, index) of NavList" :key="index" :class="{ active: activeNavIndex === index }" @click="clickTab(index, item.name)">
      <van-icon :name="item.icon" />
      <div>{{ item.title }}</div>
    </section>
  </footer>
</template>

<script>
  import next from '@/assets/imgs/next.jpg'
  import nav1 from '@/assets/imgs/nav1.jpg'
  import nav2 from '@/assets/imgs/nav2.jpg'
  import nav3 from '@/assets/imgs/nav3.jpg'
  import nav4 from '@/assets/imgs/nav4.jpg'

  export default {
    name: 'FooterNav',
    props: {
      activeNavIndex: { type: Number, default: 0 }
    },
    data() {
      return {
        NavList: [
          { title: '服务大厅', name: 'Home', icon: nav1 },
         // { title: '业务办理', name: 'Category', icon: nav2 },
          { title: '信息公告', name: 'Cart', icon: nav3 },
          { title: '个人中心', name: 'Me', icon: nav4 }
        ],
      };
    },
    methods: {
      clickTab(index, name) {
        if (this.activeNavIndex === index) return;
        this.$router.push({ name });
      }
    }
  }
</script>

<style lang="scss" scoped>
  $color:#b532e9;

  #tabNav {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 13.5vw;
    display: flex;
    background: #fff;
    z-index: 100;
    section {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      color: #7d7e80;
      font-size: 24px;
      padding-bottom: 4px;

      div {
        font-size: 12px;
      }
    }
    .active { color: $color; }
  }
</style>