import Vue from 'vue';
import Router from 'vue-router';

const Home = () => import('@/pages/Home'); // 首页
const GoodsDetails = () => import('@/pages/GoodsDetails'); // 商品详情
const City = () => import('@/pages/City'); // 城市选择
const Collection = () => import('@/pages/Collection'); // 我的收藏
const BrowseHistory = () => import('@/pages/BrowseHistory'); // 浏览历史
const CommentCenter = () => import('@/pages/CommentCenter'); // 评价中心
const CommentGoods = () => import('@/pages/CommentGoods'); // 评价商品
const CommentDetails = () => import('@/pages/CommentDetails'); // 评价详情
const OrderManage = () => import('@/pages/OrderManage'); // 订单管理
const Login = () => import('@/pages/Login'); // 登录、注册
const OrderPayment = () => import('@/pages/OrderPayment'); // 订单支付
const AddressManage = () => import('@/pages/AddressManage'); // 地址管理
const AddressEdit = () => import('@/pages/AddressEdit'); // 地址编辑
const InfoNotice = () => import('@/pages/InfoNotice'); // 信息公告
const WatercutInfo = () => import('@/pages/WatercutInfo'); //停水信息
const PublicItem = () => import('@/pages/PublicItem'); //公共事项
const WaterQuality = () => import('@/pages/WaterQuality'); //水质信息
const ChargeStandard = () => import('@/pages/ChargeStandard'); //收费标准
const DetailPages = () => import('@/pages/DetailPages'); //详情页

const OwnCenter = () => import('@/pages/OwnCenter'); // 个人中心
const UserBind = () => import('@/pages/UserBind'); // 户号绑定
const AccountInfo = () => import('@/pages/AccountInfo'); //账户信息
const PayRecord = () => import('@/pages/PayRecord'); // 缴费明细
const WaterRecord = () => import('@/pages/WaterRecord'); // 用水明细
const WaterPayment = () => import('@/pages/WaterPayment'); //户号管理
const pay = () => import('@/pages/pay'); //缴费
const personalDetails = () => import('@/pages/personalDetails'); //个人信息
const guestNoInfo = () => import('@/pages/guestNoInfo'); //户号信息
const guestNoSync = () => import('@/pages/guestNoSync'); //户号同步


Vue.use(Router);
/**
 * keepAlive 需要缓存的页面
 */
const router =  new Router({
  mode: 'history',
  base: '/wx/',
  routes: [
    { path: '/', redirect: { name: 'Me' } },
    { path: '/home', name: 'Home', component: Home, meta: { keepAlive: true, index: 1 }}, // 首页
    { path: '/category', name: 'Category', component: InfoNotice, meta: { index: 2 } }, // 商品分类
    { path: '/cart', name: 'Cart', component: InfoNotice, meta: { index: 3 } }, // 购物车
    { path: '/me', name: 'Me', component: OwnCenter, meta: { index: 4 } }, // 个人中心
    { path: '/city', name: 'City', component: City, meta: { keepAlive: true, index: 5 } }, // 城市选择
    { path: '/collection', name: 'Collection', component: Collection, meta: { index: 6 } }, // 我的收藏
    { path: '/browseHistory', name: 'BrowseHistory', component: BrowseHistory, meta: { index: 7 } }, // 浏览历史
    { path: '/orderManage', name: 'OrderManage', component: OrderManage, meta: { index: 8 } }, // 订单管理
    { path: '/commentCenter', name: 'CommentCenter', component: CommentCenter, meta: { index: 9 } }, // 评价中心
    { path: '/commentGoods', name: 'CommentGoods', component: CommentGoods, props: route => ({ goodsId: route.query.goodsId, order_id: route.params.order_id, orderNum: route.params.orderNum }), meta: { index: 10 } }, // 评价商品
    { path: '/commentDetails', name: 'CommentDetails', component: CommentDetails, props: route => ({ commentId: route.query.commentId }), meta: { index: 11 } }, // 评价详情
    { path: '/orderPayment', name: 'OrderPayment', component: OrderPayment, meta: { index: 12 } }, // 订单支付
    { path: '/addressManage', name: 'AddressManage', component: AddressManage, meta: { index: 13 } }, // 地址管理
    { path: '/addressEdit', name: 'AddressEdit', component: AddressEdit, meta: { index: 14 } }, // 地址编辑
    { path: '/goodsDetails', name: 'GoodsDetails', component: GoodsDetails, props: route => ({ goodsId: route.query.goodsId }), meta: { index: 15 } }, // 商品信息
    { path: '/login', name: 'Login', component: Login, meta: { index: 16 } }, // 登录、注册
    { path: '/watercutInfo', name: 'WatercutInfo', component: WatercutInfo, meta: { index: 17 } }, //停水信息
    { path: '/publicItem', name: 'PublicItem', component: PublicItem, meta: { index: 18 } }, //公共事项
    { path: '/waterQuality', name: 'WaterQuality', component: WaterQuality, meta: { index: 19 } }, //水质信息
    { path: '/chargeStandard', name: 'ChargeStandard', component: ChargeStandard, meta: { index: 20 } }, //收费标准
    { path: '/detailPages', name: 'DetailPages', component: DetailPages, meta: { index: 21 } }, //详情页
    //支付系统模块
    { path: '/userBind', name: 'UserBind', component: UserBind, meta: { index: 22 } }, //户号绑定
    { path: '/AccountInfo', name: 'AccountInfo', component: AccountInfo, meta: { index: 23 } }, //账户信息
    { path: '/payRecord', name: 'PayRecord', component: PayRecord, meta: { index: 24 } }, //缴费明细
    { path: '/waterRecord', name: 'WaterRecord', component: WaterRecord, meta: { index: 25 } }, //用水明细
    { path: '/waterPayment', name: 'WaterPayment', component: WaterPayment, meta: { index: 26 } }, //户号管理
    { path: '/pay', name: 'pay', component: pay, meta: { index: 27 } }, //户号管理
    { path: '/personalDetails', name: 'personalDetails', component: personalDetails, meta: { index: 28 } }, //个人信息
    { path: '/guestNoInfo', name: 'guestNoInfo', component: guestNoInfo, meta: { index: 29 } }, //户号信息
    { path: '/guestNoSync', name: 'guestNoSync', component: guestNoSync, meta: { index: 30 } }, //户号同步
    { path: '*', redirect: { name: 'Home' } }
  ]
});

const title = {
  Home: '微信营业厅',
  Category: '商品分类',
  Cart: '信息公告',
  Me: '个人中心',
  City: '城市选择',
  GoodsDetails: '商品详情',
  BrowseHistory: '浏览历史',
  Collection: '我的收藏',
  CommentCenter: '评价中心',
  CommentGoods: '评价商品',
  CommentDetails: '评价详情',
  Login: '登录 / 注册',
  OrderPayment: '订单支付',
  AddressManage: '地址管理',
  OrderManage: '订单管理',
  AddressEdit: '地址编辑',
  InfoNotice:'信息公告',
  WatercutInfo:'停水信息',
  PublicItem:'公共事项',
  WaterQuality:'水质信息',
  ChargeStandard:'收费标准',
  DetailPages:'详情页',
  //支付系统模块
  OwnCenter:'个人中心',
  UserBind:'户号绑定',
  AccountInfo:'账户信息',
  PayRecord:'缴费明细',
  WaterRecord:'用水明细',
  WaterPayment:'户号管理',
  pay:'户号缴费',
  personalDetails:'个人信息',
  guestNoInfo: '户号信息',
  guestNoSync: '户号同步'
};

// 注册全局导航前置钩子用来拦截导航
router.beforeEach((to, from, next) => {
  // 设置页面标题
  document.title = title[to.name];
  next();
});

export default router;
